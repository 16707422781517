import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import getProRataPriceAction, { CLEAR_PRO_RATA } from '../../../actions/billing/proRataPrice';
import useBillingChangeInfo from '../../../components/PaymentCaptureMethod/hooks/useBillingChangeInfo';
import isValidSwitch from '../../../helpers/switch/isValidSwitch';

/**
 * Custom hook to determine if a switch can load based on the upgrade offer and current offer.
 * It also handles fetching the pro-rata price if required.
 *
 * @param {Object} upgradeOffer - The offer to which the user wants to upgrade.
 * @param {Object} currentOffer - The current offer the user has.
 * @returns {Object} - An object containing:
 *   @returns {boolean} switchCanLoad - Indicates if the switch can load.
 *   @returns {boolean} switchDepsLoading - Indicates if the dependencies for the switch are loading.
 */

const useSwitchLoadRequirements = (upgradeOffer, currentOffer, onReject) => {
  const dispatch = useDispatch();
  const { isProRataMovement, nextBillingDate, isPendingChange, isOnPaidProduct } = useBillingChangeInfo({
    upgradeOffer,
  });
  const { estimatedPrice, error: proRataError, loading: proRataLoading } = useSelector(state => state.proRataPrice);

  const switchCanLoad = isValidSwitch(currentOffer, upgradeOffer, {
    nextBillingDate,
    isPendingChange,
    isOnPaidProduct,
  });
  const switchDepsError = isProRataMovement && proRataError;
  const switchDepsLoading = isProRataMovement && !proRataError && !estimatedPrice;

  if (switchDepsError) {
    onReject?.();
    dispatch({ type: CLEAR_PRO_RATA });
  }

  const fetchProRataPrice = () => {
    if (isProRataMovement && !estimatedPrice && !proRataError && !proRataLoading) {
      dispatch(getProRataPriceAction(upgradeOffer?.id));
    }
  };

  useEffect(() => {
    fetchProRataPrice();
  }, [fetchProRataPrice]);

  return { switchCanLoad, switchDepsLoading, switchDepsError };
};

export default useSwitchLoadRequirements;
