import { isEmpty } from 'lodash';
import fetch from '../fetch';
import { errorMessages } from '../../constants';

export const EXPERIENCE_API_DATA_LOADING = 'EXPERIENCE_API_DATA_LOADING';
export const EXPERIENCE_API_DATA_LOADED = 'EXPERIENCE_API_DATA_LOADED';
export const EXPERIENCE_API_UNAVAILABLE = 'EXPERIENCE_API_UNAVAILABLE';

export default () => async dispatch => {
  dispatch({ type: EXPERIENCE_API_DATA_LOADING });

  const fetched = {
    type: EXPERIENCE_API_DATA_LOADED,
  };

  try {
    const data = await dispatch(fetch('/experience-api/v1/next-best-action', {}, { requiresExperienceHeaders: true }));
    fetched.payload = data;

    if (isEmpty(data)) {
      dispatch({
        type: EXPERIENCE_API_UNAVAILABLE,
        payload: { errorMessage: 'No data found' },
      });
      return;
    }
  } catch (error) {
    fetched.type = EXPERIENCE_API_UNAVAILABLE;
    fetched.payload = { errorMessage: errorMessages.default };
  }
  dispatch(fetched);
};
