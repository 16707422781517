import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from 'styled-components';
import nebulaTheme from '@nebula/theme';

import ThanksIndex from '.';
import getAllSubscriptions from '../../actions/subscriptions/getAll';
import getAllOffers from '../../actions/offers/getAll';
import getBillingData from '../../actions/billing/getBilling';
import Loading from '../../components/Loading';
import BILLING_PROVIDERS from '../../constants/billingProviders';
import ProcessingSpinner from '../../components/PaymentProcessing/ProcessingSpinner';

const hasStoreComponentRefreshed = (storeComponent, refreshRequestTime) =>
  storeComponent.lastRefreshed?.getTime() >= refreshRequestTime.getTime() || storeComponent.error;

const ThanksLanding = () => {
  const [refreshRequestTime] = useState(new Date());

  const newSwitchJourney = useSelector(state => state.billing?.billingProvider === BILLING_PROVIDERS.NEW);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSubscriptions());
    dispatch(getBillingData());
    dispatch(getAllOffers());
  }, [dispatch]);

  const storeUpdated = useSelector(state => {
    const subscriptionsReady = hasStoreComponentRefreshed(state.subscriptions, refreshRequestTime);
    const billingReady = hasStoreComponentRefreshed(state.billing, refreshRequestTime);
    const offersReady = hasStoreComponentRefreshed(state.offers, refreshRequestTime);

    return subscriptionsReady && billingReady && offersReady;
  });

  if (storeUpdated) {
    return <ThanksIndex />;
  }

  // Keeps the loading states consistent throughout old/new switch journeys
  return newSwitchJourney ? (
    <ThemeProvider theme={nebulaTheme}>
      <ProcessingSpinner isWebview={false} enableText />
    </ThemeProvider>
  ) : (
    <Loading solidBackground />
  );
};

export default ThanksLanding;
