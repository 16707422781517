import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import nebulaTheme from '@nebula/theme';
import Button from '@nebula/button';

import { errorMessages } from '../../constants';
import { AlertContainer, AlertHeading, AlertMessage } from './Alerts.styles';

export const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const ErrorAlert = ({ heading, messages, buttonText, buttonHref, buttonOnClick, buttonPath, critical }) => {
  const dispatch = useDispatch();
  return (
    <ThemeProvider theme={nebulaTheme}>
      <AlertContainer {...setAutomationElement('error-alert')}>
        {heading !== '' && (
          <AlertHeading {...setAutomationElement('alertHeading')} messages={messages} critical={critical}>
            {heading}
          </AlertHeading>
        )}
        {Array.isArray(messages)
          ? messages.map(message => (
              <AlertMessage key={message} {...setAutomationElement('alertMessage')}>
                {message}
              </AlertMessage>
            ))
          : messages}
        {buttonText && typeof buttonText === 'string' && (
          <StyledButton
            type="primary"
            isButton={!buttonHref}
            label={buttonText}
            onClick={() => {
              if (buttonPath) {
                dispatch(push(buttonPath));
              }
              if (buttonOnClick && typeof buttonOnClick === 'function') {
                buttonOnClick();
              }
            }}
            href={buttonHref}
            {...setAutomationElement(`alertButton${buttonText.replace(/ /g, '')}`)}
          />
        )}
      </AlertContainer>
    </ThemeProvider>
  );
};

ErrorAlert.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  messages: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.node]),
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonPath: PropTypes.string,
  critical: PropTypes.bool,
};

ErrorAlert.defaultProps = {
  heading: '',
  messages: [errorMessages.default],
  buttonText: '',
  buttonHref: null,
  buttonOnClick: null,
  buttonPath: '',
  critical: false,
};

export default ErrorAlert;
