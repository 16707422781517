import { useSelector } from 'react-redux';

const useExperienceApi = () => {
  const apiState = useSelector(state => state);

  let targetedFeaturesFlowletId = '';
  let targetedFeatures = [];
  let conductricsDecisions = [];

  if (apiState?.experienceApi?.experienceApiData) {
    targetedFeaturesFlowletId = apiState.experienceApi.experienceApiData.targetedFeaturesFlowletId || '';
    targetedFeatures = apiState.experienceApi.experienceApiData.targetedFeatures || [];
    conductricsDecisions = apiState.experienceApi.experienceApiData?.ecdReport?.conductricsDecisions || [];
  }

  return { targetedFeaturesFlowletId, targetedFeatures, conductricsDecisions };
};
export default useExperienceApi;
