import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';

import actionRetry from './actionRetry';
import alerts from './alerts';
import app from './app';
import auth from './auth';
import billing from './billing';
import boost from './boost';
import creditLock from './creditLock';
import confirmSelection from './confirmSelection';
import creditSummary from './creditSummary';
import ecd from './ecd';
import error from './error';
import fulfillments from './fulfillments';
import offers from './offers';
import customers from './customers';
import productCheck from './productCheck';
import productDetails from './productDetails';
import proRataPrice from './proRataPrice';
import session from './session';
import selection from './selection';
import split from './split';
import subscriptions from './subscriptions';
import uiState from './uiState';
import voucherValidationResult from './voucherValidationResult';
import conductrics from './conductrics';
import cancellationReason from './cancellationReason';
import scores from './scores';
import upsellProgress from './trackStartUpsell';
import experienceApi from './experienceApi';

export default history =>
  combineReducers({
    actionRetry,
    alerts,
    app,
    auth,
    billing,
    boost,
    creditLock,
    confirmSelection,
    ecd,
    error,
    fulfillments,
    form,
    offers,
    creditSummary,
    customers,
    productDetails,
    productCheck,
    proRataPrice,
    router: connectRouter(history),
    session,
    selection,
    split,
    subscriptions,
    uiState,
    voucherValidationResult,
    conductrics,
    cancellationReason,
    scores,
    upsellProgress,
    experienceApi,
  });
