import { splitsForNav } from '@nebula/navigation';

export default {
  ...splitsForNav,
  applePay: 'prodMove-applePay-UpdateYourPaymentDetails',
  boostOnline: 'expn-boost-mstr',
  boostPlacement: 'boost-placements-tests',
  breachPlus: 'BreachPlus',
  scoreComponents: 'CEoC_ScoreComponents',
  scoreHistory: 'CEoC_ScoreHistory',
  showRecycleBanner: 'recycleProcess',
  prodmoveDD: 'ProdmoveDirectDebit',
  fullCreditReportIdPlus: 'ceoc-full-report-id-plus',
  fullCreditReportBreachPlus: 'ceoc-full-report-breach-plus',
  fullCreditReportBreach: 'ceoc-full-report-breach',
  fullCreditReportFree: 'ceoc-full-report-free',
  productMovementBreachExtension: 'product-movement-breach-extension',
  protectWebRollout: 'protect-web-rollout',
  applePayWebRollout: 'applepay-web-rollout',
  debugRecurlyThreeDs: 'debug-recurly-threeds',
  disableSavedPaymentMethod: 'prodmove-disable-saved-payment-methods',
};
