import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { upsellTransition, UpsellTransitionStages } from '@experian-uk/web-common-mobile-view-helpers';
import { trackSwitchFormAttempt } from '../../helpers/switch/analytics';
import { PAYMENT_LOG_STEPS } from '../../constants/paymentLogSteps';
import useTrackUpsellStage from '../PaymentOrchestrator/hooks/useTrackUpsellStage';
import { PaymentContext } from '../PaymentOrchestrator/PaymentContextProvider';
import paymentMethodStoredTransition from '../PaymentOrchestrator/transitions/paymentMethodStoredTransition';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../PaymentOrchestrator/constants/paymentErrors';
import PaymentError from './PaymentError';
import PaymentHeading from './PaymentHeading';
import PaymentReview from './PaymentReview';
import PaymentMethodEntry from './PaymentMethodEntry';
import { Main, Alert, Header, Review, Methods } from './index.styles';
import GoBack from './GoBack';

const PaymentCaptureMethod = () => {
  const paymentContext = useContext(PaymentContext);
  const { paymentErrors, raisePaymentError, clearPaymentErrors, isWebview } = paymentContext;

  const [nextStepDisabled, setNextStepDisabled] = useState(false);
  const trackUpsellStage = useTrackUpsellStage();
  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentErrors.length) {
      upsellTransition({ stage: UpsellTransitionStages.UNLOCK });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [paymentErrors.length]);

  const lockNavigation = () => {
    upsellTransition({ stage: UpsellTransitionStages.LOCK });
    setNextStepDisabled(true);
    clearPaymentErrors();
  };

  const handleSubmitAttempt = () => {
    trackUpsellStage(PAYMENT_LOG_STEPS.TOKENIZATION_STARTED);
    lockNavigation();
  };

  const handleSuccess = async (cardToken, paymentType) => {
    trackSwitchFormAttempt(true, paymentType);
    trackUpsellStage(PAYMENT_LOG_STEPS.SWITCH_FORM_COMPLETE);

    const runBeforeTransition = () => setNextStepDisabled(false);
    await paymentMethodStoredTransition(
      paymentContext,
      { newMethodToken: cardToken.id, isAddingPaymentMethod: true },
      runBeforeTransition
    )(dispatch);
  };

  const handleFailure = (error, paymentType) => {
    setNextStepDisabled(false);
    clearPaymentErrors();
    raisePaymentError({
      message: error.message,
      group: PAYMENT_ERROR_GROUPS.TOKENIZATION,
      type: PAYMENT_ERROR_TYPES.TOKENIZATION.GENERATING_PAYMENT_TOKEN,
    });

    trackSwitchFormAttempt(false, paymentType);
    trackUpsellStage(PAYMENT_LOG_STEPS.SWITCH_FORM_FAILED);
  };

  const handleExistingPaymentMethod = async () => {
    lockNavigation();

    trackSwitchFormAttempt(true, 'existing_card');
    trackUpsellStage(PAYMENT_LOG_STEPS.SWITCH_FORM_COMPLETE);

    const runBeforeTransition = () => setNextStepDisabled(false);
    await paymentMethodStoredTransition(
      paymentContext,
      { newMethodToken: null, isAddingPaymentMethod: false },
      runBeforeTransition
    )(dispatch);
  };

  const paymentError = paymentErrors.length ? paymentErrors[paymentErrors.length - 1] : null;

  return (
    <React.Fragment>
      {!nextStepDisabled && !isWebview && <GoBack />}
      {paymentError && (
        <Alert key={paymentError.type} isWebview={isWebview}>
          <PaymentError error={paymentError} />
        </Alert>
      )}
      <Header>
        <PaymentHeading />
      </Header>
      <Main>
        <Review>
          <PaymentReview />
        </Review>
        <Methods>
          <PaymentMethodEntry
            disabled={nextStepDisabled}
            onSubmit={handleSubmitAttempt}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            onExistingPaymentMethod={handleExistingPaymentMethod}
          />
        </Methods>
      </Main>
    </React.Fragment>
  );
};

export default PaymentCaptureMethod;
