import React from 'react';
import { isEmpty } from 'lodash';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import Analytics, { InteractionInputEvent, OptimisationInputEvent } from '@experian-uk/web-common-analytics';
import { BannerBenefitsContainer, BenefitsAnchor, BenefitsHeading, BenefitsText } from './benefitsBanner.styles';
import useExperienceApi from '../../../hooks/useExperienceApi';
import { getEnv } from '../../Context/env';
import InViewComponent from '../../InView';
import { flowletDecisions } from '../../../constants';

const triggerEcd2Event = (event, cta) => {
  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: cta.text,
      action: 'click',
      status: 'success',
    })
  );
  window.location.href = cta?.destination?.includes('experian') ? cta.destination : `${getEnv().REACT_APP_INS_URL}`;
};

const triggerECD2EventsOnload = ({ testName, testVariant }) => {
  Analytics.publishOnce(
    OptimisationInputEvent.fromObject({
      testName,
      testVariant,
      testArea: 'Product Movement',
    }),
    testName
  );
};

const BenefitsBanner = () => {
  const { targetedFeaturesFlowletId, targetedFeatures, conductricsDecisions } = useExperienceApi();

  const targetedFeature = targetedFeatures?.[0];

  if ((!targetedFeature || targetedFeatures.length === 0) && conductricsDecisions.length === 0) {
    return null;
  }

  if ((!targetedFeature || targetedFeatures.length === 0) && conductricsDecisions.length > 0) {
    let selectedAgent = {};
    const flowletDecision = flowletDecisions.find(value => value?.flowletId === targetedFeaturesFlowletId) || {};
    const { testAgentWithControlECD } = flowletDecision;
    const cachedTestData = !isEmpty(testAgentWithControlECD)
      ? testAgentWithControlECD.reduce((acc, testData) => {
          acc[testData?.agent] = testData;
          return acc;
        }, {})
      : {};

    const decision = conductricsDecisions.find(agent => cachedTestData[agent?.id]) || {};
    selectedAgent = cachedTestData[decision?.id] || {};

    if (['A'].includes(decision?.variant)) {
      triggerECD2EventsOnload({
        testName: selectedAgent?.ecdTestName || 'Prod Move Downsell Insights',
        testVariant: decision?.variant,
      });
    }
    return null;
  }

  const {
    title = '',
    description = '',
    cta = { text: '', destination: `${getEnv().REACT_APP_INS_URL}` },
    actionName = '',
    decisionName = '',
  } = targetedFeature;

  triggerECD2EventsOnload({ testName: decisionName, testVariant: actionName });

  return (
    <InViewComponent
      label="product movement"
      data={{
        card_name: actionName,
        card_type: 'standard',
      }}
    >
      <BannerBenefitsContainer {...setAutomationElement('benefitsBanner')}>
        <BenefitsHeading>{title}</BenefitsHeading>
        <BenefitsText>{description}</BenefitsText>
        <BenefitsAnchor onClick={e => triggerEcd2Event(e, cta)}>{cta.text}</BenefitsAnchor>
      </BannerBenefitsContainer>
    </InViewComponent>
  );
};

export default BenefitsBanner;
