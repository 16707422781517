import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { products, fulfillmentStatuses, subscriptionActions } from '../../../constants';
import { TrialDuration } from '../constants';

/**
 * Custom hook to calculate billing change information based on the current subscription and upgrade offer.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.upgradeOffer - The upgrade offer details.
 * @param {boolean} params.isTrial - Flag indicating if the current subscription is a trial.
 * @returns {Object} Billing change information.
 * @returns {Object} return.billingStartDate - The calculated start date for the new billing period.
 * @returns {Object} return.nextBillingDate - The end date of the current billing period.
 * @returns {boolean} return.isDeferredUpsell - Flag indicating if the upgrade offer is a deferred upsell.
 * @returns {string} return.periodicity - The billing frequency of the upgrade offer, capitalized.
 * @returns {number} return.price - The price of the upgrade offer.
 *
 * @example
 * const { billingStartDate, nextBillingDate, isDeferredUpsell, periodicity, price } = useBillingChangeInfo({
 *   upgradeOffer: {
 *     terms: [{ billingFrequency: 'monthly', price: 29.99 }],
 *     family: 'premium'
 *   },
 *   isTrial: true
 * });
 */

const DEFERRED_PRODUCT_MOVEMENTS = {
  [products.credit_expert]: [products.identity],
};

const BREACH_FAMILIES = [products.breach, products.breachPlus];

const useBillingChangeInfo = ({ upgradeOffer, isTrial }) => {
  const currentSubscription = useSelector(state => state.subscriptions.current);
  const proRataPrice = useSelector(state => state.proRataPrice.estimatedPrice);

  const isMovingToCeFromId =
    currentSubscription.family === products.identity && upgradeOffer?.family === products.credit_expert;

  const isBreachCustomer = BREACH_FAMILIES.includes(currentSubscription.family);

  const isOnPaidProduct = [products.identity, products.credit_expert].includes(currentSubscription.family);

  const isFulfillmentFailed = currentSubscription.fulfillmentStatus === fulfillmentStatuses.failed;

  let isDeferredUpsell = DEFERRED_PRODUCT_MOVEMENTS[currentSubscription.family]?.includes(upgradeOffer.family) ?? false;
  const { nextBillingDate, billingEndDate } = useSelector(state => ({
    nextBillingDate: dayjs(state.billing.nextBillingDate),
    billingEndDate: dayjs(state.billing.endDate),
  }));

  const isPendingChange = [subscriptionActions.activation, subscriptionActions.cancellation].includes(
    currentSubscription.defermentInfo?.type
  );

  const remainingTimeUntilDate = billingEndDate.isAfter(dayjs()) ? billingEndDate : null;

  const { billingFrequency, price } = upgradeOffer?.terms?.[0] ?? {};

  const periodicity = billingFrequency ? billingFrequency.charAt(0).toUpperCase() + billingFrequency.slice(1) : null;

  const trialPeriod = isTrial ? TrialDuration : 0;
  let billingStartDate;
  const invalidPaidSubscriptionState = isFulfillmentFailed && isOnPaidProduct;
  // The isDeferredUpsell flag is always set to false if invalidPaidSubscriptionState is true because
  // a failed fulfillment status on a paid product invalidates the possibility of a deferred upsell.
  if (invalidPaidSubscriptionState) {
    billingStartDate = dayjs().add(trialPeriod, 'day');
    isDeferredUpsell = false;
  } else if (isBreachCustomer) {
    billingStartDate = dayjs(remainingTimeUntilDate).add(trialPeriod, 'day');
  } else if (isDeferredUpsell) {
    billingStartDate = dayjs(nextBillingDate).add(1, 'day');
  } else {
    billingStartDate = dayjs().add(trialPeriod, 'day');
  }

  const isProRataMovement = isMovingToCeFromId;

  return {
    billingStartDate,
    nextBillingDate,
    remainingTimeUntilDate,
    isDeferredUpsell,
    periodicity,
    price,
    proRataPrice,
    isProRataMovement,
    isPendingChange,
    isOnPaidProduct,
  };
};

export default useBillingChangeInfo;
