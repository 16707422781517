import {
  EXPERIENCE_API_DATA_LOADING,
  EXPERIENCE_API_DATA_LOADED,
  EXPERIENCE_API_UNAVAILABLE,
} from '../actions/experienceApi/getExperienceApi';

const initialState = {
  error: null,
  experienceApiData: {},
  fetching: false,
  errorMessage: null,
};

const experienceApiReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case EXPERIENCE_API_DATA_LOADING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case EXPERIENCE_API_DATA_LOADED: {
      return {
        ...state,
        experienceApiData: action.payload,
        fetching: false,
      };
    }
    case EXPERIENCE_API_UNAVAILABLE:
      return {
        ...state,
        error: true,
        fetching: false,
        experienceApiData: {},
        errorMessage: action?.payload?.errorMessage,
      };
    default:
      return state;
  }
};

export default experienceApiReducer;
