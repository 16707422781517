import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import nebulaTheme from '@nebula/theme';
import GlobalStyles from '@nebula/global-styles';
import { Route } from 'react-router';

import { products, stateVariables } from '../../../constants';
import ProcessingSpinner from '../../../components/PaymentProcessing/ProcessingSpinner';
import PaymentOrchestrator from '../../../components/PaymentOrchestrator';
import useCurrentOffer from '../../../hooks/useCurrentOffer';
import useTargetOffer from '../../../hooks/useTargetOffer';
import { trackSwitchRejected } from '../../../helpers/switch/analytics';
import usePageView from '../../../hooks/usePageView';
import trackStartUpsell from '../../../actions/upsellstarttrackerservice/trackStartUpsell';
import startSecureMode from '../../../actions/app/startSecureMode';
import SwitchIndex from '..';
import useSwitchLoadRequirements from './useSwitchLoadRequirements';

/*
    We're planning to incrementally import (and improve within reason) aspects of the existing Switch page as we build out the new provider flow from this page.
    This approach has been chosen to minimise the amount of branching we need to do within an already complex switch page. This does increase risk of keeping changes
    there in sync - but it has been asked that work in the repository is kept to a minimum.

    Regression testing is planned for all product movement journeys as they are supported here.
*/
const FAMILIES_NOT_REQUIRING_PAYMENT = [products.basic, products.breach, products.breachPlus];

const PAGE_NAME = 'ProdMoveSwitchPage';

const WebSwitchPage = () => {
  const dispatch = useDispatch();

  const currentOffer = useCurrentOffer();
  const { targetOffer, targetFamily, isSourcedFromUrl, isTrial } = useTargetOffer();

  const onReject = () =>
    dispatch(
      push('/', {
        [stateVariables.persistErrorsOnLocationChange]: true,
      })
    );

  const { switchDepsLoading, switchCanLoad, switchDepsError } = useSwitchLoadRequirements(
    targetOffer,
    currentOffer,
    onReject
  );

  usePageView(PAGE_NAME, {
    productChange: { switchingTo: targetFamily, isTrial, fromDeeplink: isSourcedFromUrl },
  });

  useEffect(() => {
    dispatch(trackStartUpsell(targetFamily, isTrial));
    dispatch(startSecureMode());
  }, [dispatch, targetFamily, isTrial]);

  if (!switchCanLoad) {
    trackSwitchRejected();
    dispatch(
      push('/', {
        [stateVariables.persistErrorsOnLocationChange]: true,
      })
    );
  }

  if (!switchCanLoad || switchDepsError) {
    return null;
  }

  /* 
    fallback to the old switch page for any product change not requiring payment (free, breach), these 
    will be extracted post-migration 
  */
  if (FAMILIES_NOT_REQUIRING_PAYMENT.includes(targetOffer.family)) {
    return <Route component={SwitchIndex} />;
  }

  return (
    <ThemeProvider theme={nebulaTheme}>
      <GlobalStyles />
      {switchDepsLoading ? (
        <ProcessingSpinner isWebview={false} />
      ) : (
        <PaymentOrchestrator upgradeOffer={targetOffer} isTrial={isTrial} />
      )}
    </ThemeProvider>
  );
};

export default WebSwitchPage;
