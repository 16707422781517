import dayjs from 'dayjs';
import { products } from '../../constants';

const VALID_MOVEMENTS = {
  [products.basic]: [products.identity, products.credit_expert, products.breach, products.breachPlus],
  [products.identity]: [products.basic, products.credit_expert],
  [products.credit_expert]: [products.basic, products.identity],
  [products.breach]: [products.breachPlus, products.identity, products.credit_expert],
  [products.breachPlus]: [products.basic, products.breach, products.identity, products.credit_expert],
};

const isValidSwitch = (
  currentOffer,
  targetOffer,
  { isOnPaidProduct, isPendingChange, nextBillingDate } = {
    isOnPaidProduct: false,
    isPendingChange: false,
    nextBillingDate: null,
  }
) => {
  const currentFamily = currentOffer?.family;
  const targetFamily = targetOffer?.family;

  if (!currentFamily || !targetFamily) {
    return false;
  }

  const validTargetFamilies = VALID_MOVEMENTS[currentFamily];

  const isBillingDateToday = nextBillingDate && dayjs(nextBillingDate).isSame(dayjs(), 'day');
  const validPaidToPaidMovement = !isPendingChange && !isBillingDateToday;

  return validTargetFamilies.includes(targetFamily) && (!isOnPaidProduct || validPaidToPaidMovement);
};

export default isValidSwitch;
