import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendMobileErrorNotification,
  upsellTransition,
  UpsellTransitionStages,
} from '@experian-uk/web-common-mobile-view-helpers';

import MobileSwitch from './switch';
import getCustomer from '../../actions/auth/getCustomer';
import getAllOffers from '../../actions/offers/getAll';
import getAllSubscriptions from '../../actions/subscriptions/getAll';
import getScore from '../../actions/scores/getScore';
import getBillingProvider from '../../actions/billing/getBillingProvider';
import BILLING_PROVIDERS from '../../constants/billingProviders';
import { getPaymentMethods } from '../../actions/billing/paymentMethods';
import { getBilling } from '../../actions/billing';

const CORE_DEPENDENCIES = [getCustomer, getAllOffers, getAllSubscriptions, getPaymentMethods, getBilling, getScore];

// A home for Webview routes - will be responsible for anything that needs to be available to all routes
const MobileRoutes = () => {
  const [initialised, setInitialised] = useState(false);

  const mobileSwitchEnabled = useSelector(state => state.billing.billingProvider === BILLING_PROVIDERS.NEW);

  const dispatch = useDispatch();

  useEffect(() => {
    const initialise = async () => {
      /* Check we can establish a users billing provider before continuing, this will redirect to an error
    screen if the provider cannot be established */
      try {
        upsellTransition({ stage: UpsellTransitionStages.LOCK });
        await getBillingProvider()(dispatch);
        await Promise.allSettled(CORE_DEPENDENCIES.map(dependency => dependency()(dispatch)));
        setInitialised(true);
      } catch (error) {
        sendMobileErrorNotification();
      } finally {
        upsellTransition({ stage: UpsellTransitionStages.UNLOCK });
      }
    };

    initialise();
  }, [dispatch]);

  if (!initialised) {
    // a user is seeing a native spinner
    return null;
  }

  // core deps available, each route will be responsible for telling the app that it's loaded
  return <Route exact path="/mobile/switch" component={mobileSwitchEnabled ? MobileSwitch : null} />;
};

export default MobileRoutes;
