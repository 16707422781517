import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router';
import { push } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import { AnimatedLogo, baseTheme } from '@experian-uk/corvetteuk-common-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import getCustomer from '../actions/auth/getCustomer';
import getBilling from '../actions/billing/getBilling';
import getBillingProvider from '../actions/billing/getBillingProvider';
import { getPaymentMethods } from '../actions/billing/paymentMethods';
import getAllSubscriptions from '../actions/subscriptions/getAll';
import getAllOffers from '../actions/offers/getAll';
import getAlertCount from '../actions/alerts/getCount';
import getProductDetails from '../actions/product/getDetails';
import getBoostStatus from '../actions/boost/getStatus';
import getScore from '../actions/scores/getScore';
import getLockStatusIfCE from '../actions/creditLock/getStatus';
import getSelections from '../actions/conductrics/getSelections';
import { subscriptionSchema } from '../schemas';
import { products, descriptors } from '../constants';
import NavigationWrapper from '../components/NavigationWrapper';
import Footer from '../components/Footer';
import { getSplitStatus } from '../reducers/selectors';

import { getOfferById } from '../reducers/offers';
import ProtectedRoute from '../components/ProtectedRoute';
import ScrollToTop from './scrollToTop';
import { EnvProvider, EnvConsumer } from '../components/Context/env';
import { FullPageContainer } from '../components/Container';
import HomePage from './home';
import ConfirmPage from './confirm';
import WhichProductPage from './which-product';
import splitsList from '../constants/splits';
import getCurrentSubscriptionPrice from '../reducers/selectors/currentSubscriptionPrice';
import ThanksLanding from './thanks/landing';
import SwitchPage from './switch';
import WebSwitchPage from './switch/migration';
import BILLING_PROVIDERS from '../constants/billingProviders';
import getExperienceApi from '../actions/experienceApi/getExperienceApi';

@withRouter
@connect(
  state => ({
    currentSubscription: state.subscriptions.current,
    currentSubscriptionPrice: getCurrentSubscriptionPrice(state),
    isDeferred: !!state.subscriptions.current?.defermentInfo,
    offers: state.offers,
    creditExpertOffer: getOfferById(state.offers, get(state.offers[products.credit_expert], 'offerId')),
    productDetails: state.productDetails,
    query: state.router.location.query,
    splitsReady: state.split.fetched || state.split.error || state.auth.error,
    directDebitEnabled: getSplitStatus(state.split, [splitsList.prodmoveDD])[splitsList.prodmoveDD],
    billingProvider: state.billing.billingProvider,
  }),
  dispatch =>
    bindActionCreators(
      {
        getCustomer,
        getBilling,
        getBillingProvider,
        getAllSubscriptions,
        getPaymentMethods,
        getProductDetails,
        getAllOffers,
        getAlertCount,
        getBoostStatus,
        getLockStatusIfCE,
        getSelections,
        getExperienceApi,
        getScore,
        push,
      },
      dispatch
    )
)
export default class DefaultLayout extends React.Component {
  static splitProtectedRoutes = ['confirm'];

  static propTypes = {
    getCustomer: PropTypes.func.isRequired,
    getBilling: PropTypes.func.isRequired,
    getBillingProvider: PropTypes.func.isRequired,
    getAllSubscriptions: PropTypes.func.isRequired,
    getScore: PropTypes.func.isRequired,
    getPaymentMethods: PropTypes.func.isRequired,
    getAlertCount: PropTypes.func.isRequired,
    getBoostStatus: PropTypes.func.isRequired,
    getLockStatusIfCE: PropTypes.func.isRequired,
    currentSubscription: PropTypes.shape(subscriptionSchema),
    currentSubscriptionPrice: PropTypes.number,
    getAllOffers: PropTypes.func.isRequired,
    offers: PropTypes.shape({
      isLoaded: PropTypes.bool,
    }),
    creditExpertOffer: PropTypes.shape({
      terms: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.number,
        })
      ),
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
    query: PropTypes.shape({
      switchingTo: PropTypes.string,
    }),
    getProductDetails: PropTypes.func.isRequired,
    splitsReady: PropTypes.bool,
    directDebitEnabled: PropTypes.bool,
    getSelections: PropTypes.func.isRequired,
    getExperienceApi: PropTypes.func.isRequired,
    isDeferred: PropTypes.bool,
    billingProvider: PropTypes.string,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentSubscription: {},
    currentSubscriptionPrice: undefined,
    offers: {
      isLoaded: false,
    },
    creditExpertOffer: {},
    query: {},
    directDebitEnabled: false,
    splitsReady: false,
    isDeferred: false,
    billingProvider: null,
  };

  state = {
    ready: !this.props.location.pathname.includes('switch'),
  };

  async componentDidMount() {
    /* Check we can establish a users billing provider before continuing, this will redirect to an error
    screen if the provider cannot be established */
    try {
      await this.props.getBillingProvider();

      const { offers } = this.props;
      const getOffers = () => (!offers.isLoaded ? this.props.getAllOffers() : Promise.resolve());

      const getSubscriptionDependants = async () => {
        await this.props.getAllSubscriptions();
        await this.props.getScore();
        await this.props.getLockStatusIfCE();
      };

      Promise.all([
        this.props.getCustomer(),
        this.props.getBilling(),
        this.props.getPaymentMethods(),
        this.props.getProductDetails(),
        this.props.getBoostStatus(),
        getSubscriptionDependants(),
        getOffers(),
      ]).finally(() => {
        this.props.getSelections();
        this.props.getExperienceApi();
        this.setState({
          ready: true,
        });
      });

      this.props.getAlertCount();
    } catch (error) {
      this.props.push('/error/provider');
    }
  }

  render() {
    const { creditExpertOffer, currentSubscription, location, query, currentSubscriptionPrice, isDeferred } =
      this.props;

    const isSplitProtectedRoute = DefaultLayout.splitProtectedRoutes.some(route =>
      this.props.location.pathname.includes(route)
    );

    const { switchingTo } = query;

    const currentLocation = {
      ...location,
      switchingTo,
    };

    const dependenciesReady = this.state.ready && this.props.billingProvider;
    const splitsAvailable = !isSplitProtectedRoute || this.props.splitsReady;

    return dependenciesReady && splitsAvailable ? (
      <ThemeProvider theme={baseTheme}>
        <ScrollToTop>
          <EnvProvider>
            <EnvConsumer>
              {env => (
                <React.Fragment>
                  <NavigationWrapper />
                  <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route
                      exact
                      path="/switch"
                      component={this.props.billingProvider === BILLING_PROVIDERS.NEW ? WebSwitchPage : SwitchPage}
                    />
                    <Route exact path="/thanks" component={ThanksLanding} />
                    <Route exact path="/confirm">
                      {this.props.directDebitEnabled ? <ConfirmPage /> : <Redirect to="/" />}
                    </Route>
                    <ProtectedRoute
                      exact
                      path="/our-products/:returnUrl?"
                      component={WhichProductPage}
                      allowedDescriptors={[descriptors.free]}
                    />
                    <Redirect from="*" to="/" />
                  </Switch>
                  <Footer
                    creditExpertOffer={creditExpertOffer}
                    currentLocation={currentLocation}
                    currentSubscriptionFamily={currentSubscription.family}
                    productPrice={currentSubscriptionPrice}
                    urls={{
                      experianUrl: env.REACT_APP_EXPERIAN_URL,
                      insUrl: env.REACT_APP_INS_URL,
                    }}
                    isDeferred={isDeferred}
                  />
                </React.Fragment>
              )}
            </EnvConsumer>
          </EnvProvider>
        </ScrollToTop>
      </ThemeProvider>
    ) : (
      <FullPageContainer data-automation-test-element="default-routes-loading">
        <AnimatedLogo />
      </FullPageContainer>
    );
  }
}
