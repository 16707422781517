import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ErrorAlert from './ErrorAlert';

@connect(state => ({
  errors: state.error,
}))
export default class Errors extends React.Component {
  static propTypes = {
    filter: PropTypes.func,
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        critical: PropTypes.bool,
        error: PropTypes.string,
      })
    ),
    page: PropTypes.string.isRequired,
  };

  static defaultProps = {
    filter: x => x,
    errors: [],
  };

  static getErrorDisplayContent(error) {
    return {
      heading: error.heading,
      messages: error.messages,
      buttonPath: error.buttonPath,
      buttonText: error.buttonText,
    };
  }

  render() {
    const { errors, page } = this.props;
    const errorAlerts = [];

    errors.filter(this.props.filter).forEach((err, index) => {
      if (err.critical) {
        let errorAlertObject;
        try {
          errorAlertObject = JSON.parse(err.error);
        } catch (error) {
          errorAlertObject = { messages: [err.error] };
        }
        delete errorAlertObject.critical;
        errorAlerts.push({ ...errorAlertObject, page: err.page ?? null, key: `error-${index}` });
      }
    });

    const onPageErrors = errorAlerts.filter(error => !error.page || page === error.page);
    const deduplicatedOnPageErrors = onPageErrors.reduce((dedupErrors, currentError) => {
      const alreadyExists = dedupErrors.find(
        existingError =>
          JSON.stringify(Errors.getErrorDisplayContent(existingError)) ===
          JSON.stringify(Errors.getErrorDisplayContent(currentError))
      );

      return alreadyExists ? dedupErrors : [...dedupErrors, currentError];
    }, []);

    return deduplicatedOnPageErrors.map(err => <ErrorAlert key={err.key} {...err} />);
  }
}
