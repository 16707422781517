import get from 'lodash/get';
import { LOCATION_CHANGE } from 'connected-react-router';
import { stateVariables } from '../constants';

const shouldClearErrors = (metaData = {}) => !!metaData.clearErrors;

const shouldPersistErrors = payloadData =>
  get(payloadData, `location.state[${stateVariables.persistErrorsOnLocationChange}]`, false);

export const CLEAR_ERROR = 'CLEAR_ERROR';

export default (state = [], action = {}) => {
  const { meta = {}, payload, type } = action;

  if (!payload) {
    return state;
  }

  if (type === CLEAR_ERROR) {
    return state.filter(s => s.type !== payload);
  }

  if (shouldClearErrors(meta) || (type === LOCATION_CHANGE && !shouldPersistErrors(payload))) {
    return state.filter(s => s.persist);
  }

  if (meta.shouldClearErrorsOfType) {
    return state.filter(s => s.type !== meta.shouldClearErrorsOfType);
  }

  if (!action.error) {
    return state;
  }

  const errors = state.filter(s => s.error !== action.payload.message || s.persist);

  errors.push({
    type: meta.key || action.payload.name || 'Error',
    error: action.payload.message,
    critical: meta.critical !== false,
    persist: meta.persist || false,
    ...(meta.page ? { page: meta.page } : {}),
  });

  return errors;
};
