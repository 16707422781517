import React from 'react';
import styled from 'styled-components';
import { TextLinkBack } from '@nebula/text-link';

import { trackSwitchGoBackClick } from '../../helpers/switch/analytics';
import { Column, Padding } from './index.styles';

const LinkBlock = styled.p`
  ${Column};
  ${Padding};
  margin: 2.5rem 0 1rem;
`;

const GoBack = () => {
  const handleClick = () => {
    trackSwitchGoBackClick();
    window.history.back();
  };

  return (
    <LinkBlock>
      <TextLinkBack asButton onClick={handleClick}>
        Go back
      </TextLinkBack>
    </LinkBlock>
  );
};

export default GoBack;
