import styled from 'styled-components';

export const BannerBenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #b6dff7;
  background: #f1f9fd;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  padding: 16px;
  //NOTE: Do not want to alter other styling on parent, so overriding the bottom margin here (same with media query)
  margin: -32px 16px 24px;

  @media (max-width: 1200px) {
    margin: -10px 16px 24px;
  }

  p,
  h3 {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

export const BenefitsHeading = styled.h3`
  color: #2a5595;
  line-height: 24px;
  font-weight: 700;
  font-size: 16px;
`;

export const BenefitsText = styled.p`
  color: #2a5595;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const BenefitsAnchor = styled.a`
  color: #255af0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: auto;
  cursor: pointer;
`;
