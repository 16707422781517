export default [
  {
    flowletId: 't-gyhgGm3DRmM6ab6',
    testAgentWithControlECD: [
      {
        agent: 'a-IJb8jRDu3D',
        ecdTestName: 'Prod Move Downsell Insights - Credit Lock',
      },
      {
        agent: 'a-9nUxjCRJis',
        ecdTestName: 'Prod Move Downsell Insights - Credit Unlock',
      },
      {
        agent: 'a-aDC2VYbTjH',
        ecdTestName: 'Prod Move Downsell Insights - Score Improve',
      },
      {
        agent: 'a-Ql7cwaEwc6',
        ecdTestName: 'Prod Move Downsell Insights - Score Impact',
      },
    ],
  },
];
